import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from './accommodation'
import { getBookingProtectionItemsView } from './bookingProtection'
import { getCarHireItemsView } from './carHire'
import getExperienceItemsView from './getExperienceItemsView'
import getTransferItemsView from './getTransferItemsView'
import { getFlightItemsView } from './flights'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { getSubscriptionJoinItemView, getLuxPlusSubscriptionItemView } from './luxPlusSubscription'
import { getVillaViews } from './villa'
import getLuxLoyaltyLoungePassItemViews from './getLuxLoyaltyLoungePassItemViews'
import getLuxLoyaltyTransferItemView from './getLuxLoyaltyTransferItemView'
import getTourV2ExperienceItemsViews from './getTourV2ExperienceItemsViews'

export type AllCheckoutItemViews = ReturnType<typeof getAllItemViewsByType>

export interface AllItemTypesViews {
  accommodationItemsView: App.WithDataStatus<Array<App.Checkout.AccommodationOfferView>>
  experienceItemsView: App.WithDataStatus<Array<App.Checkout.ExperienceItemView>>
  flightItemsView: App.WithDataStatus<Array<App.Checkout.FlightItemView>>
  insuranceItemsView: App.WithDataStatus<Array<App.Checkout.InsuranceItemView>>
  transferItemsView: App.WithDataStatus<Array<App.Checkout.TransferItemView>>
  bookingProtectionItemsView: App.WithDataStatus<Array<App.Checkout.BookingProtectionItemView>>
  villaItemsView: App.WithDataStatus<Array<App.Checkout.VillaItemView>>
  carHireItemsView: App.WithDataStatus<Array<App.Checkout.CarHireItemView>>
  subscriptionItemView: App.WithDataStatus<Array<App.Checkout.SubscriptionJoinItemView>>
  tourV2ExperienceItemsView: App.WithDataStatus<Array<App.Checkout.TourV2ExperienceItemView>>
  luxPlusSubscriptionItemView: App.WithDataStatus<Array<App.Checkout.LuxPlusSubscriptionItemView>>
  loyaltyLoungePassItemView: App.WithDataStatus<Array<App.Checkout.LoyaltyLoungePassItemView>>
  loyaltyTransferItemView: App.WithDataStatus<Array<App.Checkout.LoyaltyTransferItemView>>
}

const getAllItemViewsByType = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => getTransferItemsView(state),
  (state: App.State) => getBookingProtectionItemsView(state),
  (state: App.State) => getVillaViews(state),
  (state: App.State) => getCarHireItemsView(state),
  (state: App.State) => getSubscriptionJoinItemView(state),
  (state: App.State) => getTourV2ExperienceItemsViews(state),
  (state: App.State) => getLuxPlusSubscriptionItemView(state),
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (state: App.State) => getLuxLoyaltyTransferItemView(state),
  (...views): App.WithDataStatus<AllItemTypesViews> => {
    return {
      hasRequiredData: views.every(o => o.hasRequiredData),
      data: {
        accommodationItemsView: views[0],
        experienceItemsView: views[1],
        flightItemsView: views[2],
        insuranceItemsView: views[3],
        transferItemsView: views[4],
        bookingProtectionItemsView: views[5],
        villaItemsView: views[6],
        carHireItemsView: views[7],
        subscriptionItemView: views[8],
        tourV2ExperienceItemsView: views[9],
        luxPlusSubscriptionItemView: views[10],
        loyaltyLoungePassItemView: views[11],
        loyaltyTransferItemView: views[12],
      },
    }
  },
)

export default getAllItemViewsByType
