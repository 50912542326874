import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from './view/accommodation'
import { OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR_V2, OFFER_TYPE_VILLA } from 'constants/offer'
import config from 'constants/config'
import { getCustomOfferItems, getCustomOfferTravellers } from './view/customOffer'
import { getVillaViews } from './view/villa'
import { getBundleAndSaveItems } from './view/bundleAndSave'
import { isCruiseOfferView } from 'checkout/lib/utils/cruises/view'
import { getCarHireItems, getCarHireItemsView } from './view/carHire'
import { checkoutContainsFlighsWithEmbeddedInsurance, getFlightItemsView, isStandaloneFlights } from './view/flights'
import { getGiftCardItems } from './view/giftCard'
import { areMultiItemTravellersTheSame } from './request/insurance'
import isInsuranceSupported from 'checkout/selectors/request/isInsuranceSupported'
import isBookingProtectionEnabled from 'checkout/selectors/request/isBookingProtectionEnabled'
import { shouldShowArrivalDetailsForm, shouldShowPaymentForm, shouldShowTravellerForm } from './stepsSelectors'
import { isBalancePending } from 'lib/payment/depositsUtils'
import {
  CHECKOUT_INSURANCE_CONTAINER_ID,
  CHECKOUT_EXPERIENCE_TRANSFER_CONTAINER_ID,
  CHECKOUT_REVIEW_CONTAINER_ID,
  CHECKOUT_TRAVELLER_CONTAINER_ID,
  CHECKOUT_ARRIVAL_DETAILS_CONTAINER_ID,
  CHECKOUT_PAYMENTS_CONTAINER_ID,
  CHECKOUT_BUNDLED_CAR_HIRE_CONTAINER_ID,
} from 'constants/checkout'
import { isLuxPlusEnabled, checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { isStandaloneLuxPlusSubscription } from './view/luxPlusSubscription'
import getTourV2ExperienceItemsViews from 'checkout/selectors/view/getTourV2ExperienceItemsViews'
import { isSpoofed, isTravelProtectionAvailableInRegion } from 'selectors/featuresSelectors'
import { isSimpleStandaloneCheckout, isStandaloneExperience } from 'selectors/checkoutSelectors'
import { INSURANCE_TITLE, TRAVEL_PROTECTION_TITLE } from 'checkout/constants/insurance'
import { getExperienceTransferBestOptions, getOneClickExperienceTransfer, isOneClickExperienceTransferEnabled } from './experienceSelectors'
import { isNonEmptyArray } from 'lib/array/arrayUtils'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import getCheckoutTravellerSchema from 'checkout/selectors/getCheckoutTravellerSchema'
import getCheckoutTotal from 'checkout/selectors/payment/getCheckoutTotal'
import { isCruiseCheckout } from './view/cruise'
import { isBundledCarHireEnabled, isCartEligibleForBundledCarHire } from 'selectors/carHireSelectors'
import getExperienceItemsView from './view/getExperienceItemsView'
import getTransferItems from './view/getTransferItems'

export interface CheckoutNumberedSection<T> {
  id: string;
  title?: string;
  checkoutNumber?: string;
  data: T;
}

export interface CheckoutSections {
  review: CheckoutNumberedSection<Array<CheckoutSectionsReview>>;
  experienceTransfer: CheckoutNumberedSection<Array<CheckoutSectionsExperienceTransfer>>
  insurance: CheckoutNumberedSection<Array<CheckoutSectionsInsurance>>;
  bundledCarHire: CheckoutNumberedSection<Array<CheckoutSectionsCarHire>>;
  travellerDetails: CheckoutNumberedSection<Array<CheckoutSectionsTravellerDetails>>;
  arrivalDetails: CheckoutNumberedSection<Array<CheckoutSectionsArrivalDetails>>;
  payment: CheckoutNumberedSection<Array<CheckoutSectionsPayment>>;
}

export type CheckoutSectionsReview = 'customOffer' | 'accommodation' | 'villa' | 'bundleAndSave' | 'cruise' | 'carHire' | 'tour' | 'tourExperience' | 'flight' | 'experience' | 'reviewTransfer' | 'giftCard' | 'customerServicePanel' | 'luxPlusInflow' | 'luxPlusStandalone' | 'loyaltyBenefits' | 'luxLoyalty'
export type CheckoutSectionsExperienceTransfer = 'experienceTransfer'
export type CheckoutSectionsInsurance = 'insurance' | 'bookingProtection' | 'spoofedInsurance'
export type CheckoutSectionsCarHire = 'bundledCarHire'
export type CheckoutSectionsTravellerDetails = 'customOfferTravellers' | 'travellerDetails' | 'carHireArrivalFlightNumber' | 'agentBookingDetails' | 'depositDetailsNotice' | 'businessBookingDetails' | 'paymentScheduleDetailsNotice'
export type CheckoutSectionsArrivalDetails = 'arrivalDetails'
export type CheckoutSectionsHighValue = 'luxLoyalty'
export type CheckoutSectionsPayment = 'instalmentBalancePaymentSchedule' | 'paymentForm'

const activeSections = <T>(sections: Array<[T, boolean]>) => sections.filter(section => Boolean(section[1])).map(section => section[0])

// @TODO: a 'cruise' is not an accommodation item
export const filterCheckoutAccommodationView = (view: App.Checkout.AccommodationOfferView) => {
  return view.offerType !== OFFER_TYPE_CRUISE && view.offerType !== OFFER_TYPE_TOUR_V2 && view.offerType !== OFFER_TYPE_VILLA
}

const accommodationViews = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (offerViews) => offerViews.data.filter(filterCheckoutAccommodationView),
)

export const checkoutShouldShowBookingProtection = createSelector(
  (state: App.State) => isBookingProtectionEnabled(state),
  // Exclude post purchase as currently only changes are allowed as part of date/package modifications
  // without removing the item, or adding it, so we don't want to show the options ever for now
  // but only display it in the summary if additional charges are required
  (state:App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (enabled, postPurchase): boolean => {
    return !postPurchase && enabled
  },
)

const checkoutShouldShowCarHireArrivalInput = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (carHireViews) => !!carHireViews.data.length && (carHireViews.data[0].item.isPickUpAtAirport ||
    carHireViews.data[0]?.pickUpLocation.name.toUpperCase().includes('AIRPORT')),
)

const getReviewSectionTitle = createSelector(
  (state: App.State) => isSimpleStandaloneCheckout(state),
  (state: App.State) => isStandaloneExperience(state),
  (state:App.State) => state.checkout.cart.isGift,
  (state: App.State) => getCarHireItems(state),
  (simpleStandaloneCheckout, standaloneExperience, isGift, carHireItems):string => {
    if (isGift) {
      return 'Let’s review your gift'
    } else if (simpleStandaloneCheckout) {
      return ''
    } else if (standaloneExperience) {
      return 'Review your experience'
    } else {
      if (config.businessTraveller.isEnabled) {
        if (carHireItems.length) {
          return 'Let’s review your booking'
        } else {
          return 'Let’s review your trip'
        }
      } else {
        return 'Let’s review your escape'
      }
    }
  },
)

const getTravellerSectionTitle = createSelector(
  (state: App.State) => getCheckoutTravellerSchema(state),
  (state:App.State) => state.checkout.cart.isGift,
  (state: App.State) => getCarHireItems(state),
  (formSchema, isGift, carHireItems) => {
    if (carHireItems.length) return 'Who\'s the driver?'
    if (isGift) return formSchema?.accommodationFlightSchema?.properties?.recipient?.title
    return 'Who\'s going?'
  },
)

const getInsuranceSectionTitle = createSelector(
  (state: App.State) => checkoutShouldShowBookingProtection(state),
  (state: App.State) => isInsuranceSupported(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isTravelProtectionAvailableInRegion(state),
  (state: App.State) => isCruiseCheckout(state),
  (
    showBookingProtection,
    isInsuranceSupported,
    isSpoofed,
    standaloneFlights,
    isTravelProtection,
    hasCruiseItems,
  ): string => {
    if (isSpoofed) return ''
    if (showBookingProtection) {
      if (standaloneFlights) {
        return 'Protect yourself if you need to cancel your flights'
      }
      return 'Protect yourself if you need to cancel your booking'
    }
    if (isInsuranceSupported && hasCruiseItems) return 'Want to add cruise protection?'
    if (isInsuranceSupported) return isTravelProtection ? TRAVEL_PROTECTION_TITLE : INSURANCE_TITLE

    return ''
  },
)

function getNumberedSections({
  reviewSections,
  experienceTransferSection,
  insuranceSections,
  bundledCarHireSection,
  travellerSections,
  arrivalDetails,
  paymentSections,
}: {
  reviewSections: Array<CheckoutSectionsReview>,
  insuranceSections: Array<CheckoutSectionsInsurance>,
  bundledCarHireSection: Array<CheckoutSectionsCarHire>,
  travellerSections: Array<CheckoutSectionsTravellerDetails>,
  arrivalDetails: Array<CheckoutSectionsArrivalDetails>,
  paymentSections: Array<CheckoutSectionsPayment>,
  experienceTransferSection: Array<CheckoutSectionsExperienceTransfer>,
}): CheckoutSections {
  const sections = [
    { name: 'review', id: CHECKOUT_REVIEW_CONTAINER_ID, data: reviewSections },
    { name: 'experienceTransfer', id: CHECKOUT_EXPERIENCE_TRANSFER_CONTAINER_ID, data: experienceTransferSection },
    { name: 'insurance', id: CHECKOUT_INSURANCE_CONTAINER_ID, data: insuranceSections },
    { name: 'bundledCarHire', id: CHECKOUT_BUNDLED_CAR_HIRE_CONTAINER_ID, data: bundledCarHireSection },
    { name: 'travellerDetails', id: CHECKOUT_TRAVELLER_CONTAINER_ID, data: travellerSections },
    { name: 'arrivalDetails', id: CHECKOUT_ARRIVAL_DETAILS_CONTAINER_ID, data: arrivalDetails },
    { name: 'payment', id: CHECKOUT_PAYMENTS_CONTAINER_ID, data: paymentSections },
  ]
  const result = new Map<string, any>()

  sections.forEach(({ name, data, id }, index) => {
    if (data.length) {
      result.set(name, { checkoutNumber: index + 1, data, id })
    }
  })

  return {
    review: result.get('review'),
    experienceTransfer: result.get('experienceTransfer'),
    insurance: result.get('insurance'),
    bundledCarHire: result.get('bundledCarHire'),
    travellerDetails: result.get('travellerDetails'),
    arrivalDetails: result.get('arrivalDetails'),
    payment: result.get('payment'),
  }
}

const isLuxPlusSubscriptionInFlowCheckout = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (isLuxPlusEnabled, isStandaloneLuxPlusCheckout, canRedeemLuxPlusBenefits): boolean =>
    isLuxPlusEnabled && !isStandaloneLuxPlusCheckout && !canRedeemLuxPlusBenefits,
)

const isLuxPlusSubscriptionStandaloneCheckout = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (isLuxPlusEnabled, isStandaloneLuxPlusCheckout, canRedeemLuxPlusBenefits): boolean =>
    isLuxPlusEnabled && isStandaloneLuxPlusCheckout && !canRedeemLuxPlusBenefits,
)

const getReviewCheckoutPageSection = createSelector(
  (state: App.State) => getCustomOfferItems(state),
  (state: App.State) => accommodationViews(state),
  (state: App.State) => getVillaViews(state),
  (state: App.State) => getBundleAndSaveItems(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getCarHireItemsView(state),
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => getTransferItems(state),
  (state: App.State) => getGiftCardItems(state),
  (state: App.State) => getTourV2ExperienceItemsViews(state),
  (state: App.State) => isSimpleStandaloneCheckout(state),
  (state: App.State) => isLuxPlusSubscriptionInFlowCheckout(state),
  (state: App.State) => isLuxPlusSubscriptionStandaloneCheckout(state),
  (state: App.State) => isOneClickExperienceTransferEnabled(state),
  (state: App.State) => getIsLuxLoyaltyEnabled(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (
    customOfferItems,
    accommodationViews,
    villaViews,
    bundleAndSaveItems,
    checkoutAccommodationOfferView,
    carHireItemsView,
    flightItemsView,
    experienceViews,
    transferViews,
    giftCardItems,
    tourV2ExperienceItemsViews,
    isSimpleStandaloneCheckout,
    isLuxPlusSubscriptionInFlowCheckout,
    isLuxPlusSubscriptionStandaloneCheckout,
    oneClickAirportTransfersABTestEnabled,
    luxLoyaltyEnabled,
    postPurchase,
  ): Array<CheckoutSectionsReview> => {
    const sections: Array<[CheckoutSectionsReview, boolean]> = [
      ['customOffer', !!customOfferItems.length],
      ['accommodation', !!accommodationViews.length],
    ]
    if (checkoutAccommodationOfferView.data?.some((view) => view.isBundled)) {
      sections.unshift(
        ['tour', !!checkoutAccommodationOfferView.data?.length],
        ['tourExperience', !!tourV2ExperienceItemsViews.data.length],
      )
    } else {
      sections.push(
        ['tour', !!checkoutAccommodationOfferView.data?.length],
        ['tourExperience', !!tourV2ExperienceItemsViews.data.length],
      )
    }
    sections.push(
      ['villa', !!villaViews.data.length],
      ['bundleAndSave', !!bundleAndSaveItems.length],
      ['cruise', !!checkoutAccommodationOfferView.data.filter(isCruiseOfferView)?.length],
      ['carHire', !!carHireItemsView.data.length],
      ['flight', !!flightItemsView.data.length],
      ['experience', !!experienceViews.data.length],
      ['reviewTransfer', !oneClickAirportTransfersABTestEnabled && !!transferViews.length],
      ['giftCard', !!giftCardItems.length],
      ['customerServicePanel', (!!accommodationViews.length || isSimpleStandaloneCheckout) && !config.agentHub.isEnabled && !isLuxPlusSubscriptionStandaloneCheckout],
      ['luxPlusInflow', isLuxPlusSubscriptionInFlowCheckout],
      ['luxPlusStandalone', isLuxPlusSubscriptionStandaloneCheckout],
      ['luxLoyalty', luxLoyaltyEnabled && !postPurchase],
    )

    return activeSections(sections)
  },
)

const getExperienceTransferCheckoutPageSection = createSelector(
  (state: App.State) => getOneClickExperienceTransfer(state),
  (state: App.State) => state.experience.experienceTransferOptions,
  (state: App.State) => getTransferItems(state),
  (state: App.State) => isOneClickExperienceTransferEnabled(state),
  (state: App.State) => getExperienceTransferBestOptions(state),
  (oneClickTransfer, transferOptions, checkoutTransferItems, oneClickAirportTransfersABTestEnabled, bestTransferOptions): Array<CheckoutSectionsExperienceTransfer> => {
    const { bestToHotelOption, bestToAirportOption } = bestTransferOptions
    const isAvaiableTransfers = !!bestToHotelOption || !!bestToAirportOption

    if (!oneClickAirportTransfersABTestEnabled || (!oneClickTransfer && !checkoutTransferItems.length) || !isAvaiableTransfers) {
      return activeSections([
        ['experienceTransfer', false],
      ])
    }

    const transferId = isNonEmptyArray(checkoutTransferItems) ? checkoutTransferItems[0].experienceId : oneClickTransfer?.id

    // Some transfer options don't include the transfer type, making it unclear whether they are to the hotel or to the airport.
    // We want to display one-click transfers only when the transfer type is known, as this information must be shown to the customer.
    const isOptionWithTransferType = transferId ? !!transferOptions[transferId]?.transfers?.some(transferOption => !!transferOption?.transferTypes?.length) : false

    return activeSections([
      ['experienceTransfer', isOptionWithTransferType],
    ])
  })

const getInsuranceCheckoutPageSection = createSelector(
  (state: App.State) => isInsuranceSupported(state),
  (state: App.State) => areMultiItemTravellersTheSame(state),
  (state: App.State) => checkoutShouldShowBookingProtection(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => checkoutContainsFlighsWithEmbeddedInsurance(state),
  (
    insuranceSupported,
    occupantsConsistent,
    shouldShowBookingProtection,
    isSpoofed,
    checkoutContainsFlighsWithEmbeddedInsurance,
  ):Array<CheckoutSectionsInsurance> => {
    return activeSections([
      ['spoofedInsurance', isSpoofed && (insuranceSupported || shouldShowBookingProtection)],
      ['insurance', !isSpoofed && insuranceSupported],
      ['bookingProtection', !isSpoofed && shouldShowBookingProtection && !checkoutContainsFlighsWithEmbeddedInsurance],
    ])
  },
)

const getBundledCarHireCheckoutPageSection = createSelector(
  (state: App.State) => isBundledCarHireEnabled(state),
  (state: App.State) => isCartEligibleForBundledCarHire(state),
  (isBundledCarHireEnabled, isCartEligibleForBundledCarHire): Array<CheckoutSectionsCarHire> => {
    return activeSections([
      ['bundledCarHire', isBundledCarHireEnabled && isCartEligibleForBundledCarHire],
    ])
  },
)

const getTravellerCheckoutPageSection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getCustomOfferTravellers(state),
  (state: App.State) => shouldShowTravellerForm(state),
  (state: App.State) => checkoutShouldShowCarHireArrivalInput(state),
  (
    existingOrder,
    customTravellers,
    shouldShowTravellerForm,
    shouldShowCarHireArrivalInput,
  ):Array<CheckoutSectionsTravellerDetails> => {
    // array of tuples as the order matters
    return activeSections([
      ['customOfferTravellers', !!existingOrder && !!customTravellers.length],
      ['travellerDetails', shouldShowTravellerForm],
      ['carHireArrivalFlightNumber', shouldShowCarHireArrivalInput],
      ['agentBookingDetails', config.agentHub.isEnabled],
      ['businessBookingDetails', config.businessTraveller.isEnabled],
      ['depositDetailsNotice', !!existingOrder?.depositDetails && isBalancePending(existingOrder.depositDetails.deposit_status)],
      ['paymentScheduleDetailsNotice', !!existingOrder?.paymentScheduleDetails],
    ])
  },
)

const getArrivalDetailsCheckoutPageSection = createSelector(
  (state: App.State) => shouldShowArrivalDetailsForm(state),
  (
    shouldShowArrivalDetailsForm,
  ):Array<CheckoutSectionsArrivalDetails> => {
    return activeSections([
      ['arrivalDetails', shouldShowArrivalDetailsForm],
    ])
  })

const getPaymentCheckoutPageSection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => shouldShowPaymentForm(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (
    existingOrder,
    showPaymentForm,
    postPurchase,
  ): Array<CheckoutSectionsPayment> => {
    // array of tuples as the order matters
    return activeSections([
      ['instalmentBalancePaymentSchedule', (postPurchase === 'instalment' || postPurchase === 'instalment-balance') && !!existingOrder?.instalmentDetails],
      ['paymentForm', showPaymentForm],
    ])
  },
)

const getPaymentSectionTitle = createSelector(
  (state: App.State) => getCheckoutTotal(state),
  (total): string => {
    return total > 0 ? 'How would you like to pay?' : ''
  },
)

export const getCheckoutPageSections = createSelector(
  (state: App.State) => getReviewCheckoutPageSection(state),
  (state: App.State) => getExperienceTransferCheckoutPageSection(state),
  (state: App.State) => getInsuranceCheckoutPageSection(state),
  (state: App.State) => getBundledCarHireCheckoutPageSection(state),
  (state: App.State) => getTravellerCheckoutPageSection(state),
  (state: App.State) => getArrivalDetailsCheckoutPageSection(state),
  (state: App.State) => getPaymentCheckoutPageSection(state),
  (state: App.State) => getPaymentSectionTitle(state),
  (state: App.State) => getReviewSectionTitle(state),
  (state: App.State) => getTravellerSectionTitle(state),
  (state: App.State) => getInsuranceSectionTitle(state),
  (
    reviewSections,
    experienceTransferSection,
    insuranceSections,
    bundledCarHireSection,
    travellerSections,
    arrivalDetails,
    paymentSections,
    paymentSectionTitle,
    reviewSectionTitle,
    travellerSectionTitle,
    insuranceSectionTitle,
  ): CheckoutSections => {
    const numberedSections = getNumberedSections({
      reviewSections,
      experienceTransferSection,
      insuranceSections,
      bundledCarHireSection,
      travellerSections,
      arrivalDetails,
      paymentSections,
    })

    return {
      review: { title: reviewSectionTitle, ...numberedSections.review },
      experienceTransfer: { title: 'Need airport transfers?', ...numberedSections.experienceTransfer },
      insurance: { title: insuranceSectionTitle, ...numberedSections.insurance },
      bundledCarHire: { title: `Unlock ${config.bundledCarHireDiscountPercentage}% off car hire for your stay`, ...numberedSections.bundledCarHire },
      travellerDetails: { title: travellerSectionTitle, ...numberedSections.travellerDetails },
      arrivalDetails: { title: 'When will you be arriving?', ...numberedSections.arrivalDetails },
      payment: { title: paymentSectionTitle, ...numberedSections.payment },
    }
  },
)

export const getHasRentalInCart = createSelector(
  (state: App.State) => state?.checkout?.cart?.items,
  (items) => items?.some(item => item.itemType === 'villa') ?? false,
)
