import { pluralizeToString } from 'lib/string/pluralize'
import offerTypeConfig from '../config/offer'
import { components } from '@luxuryescapes/contract-public-offer'
import { isOfferDomestic } from 'lib/offer/isDomestic'

import imageMap from './imageMap'

import { checkGatedOffer, getPackageUniqueKey } from 'lib/offer/offerUtils'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON, MAX_STAY, saleUnitLongReplacements } from 'constants/offer'

import { getLowestPricePackage } from 'lib/offer/getLowestPricePackage'
import { paths } from '@luxuryescapes/contract-search/server'
import { isDateBetweenOrEqual } from 'lib/datetime/dateUtils'
import config from 'constants/config'

import { groupBy, sortBy } from 'lib/array/arrayUtils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import uuidV4 from 'lib/string/uuidV4Utils'
import { getOfferInclusionDescriptionFromMarkdown, getOfferInclusionDescriptionsListFromMarkdown } from './offerInclusionMap'
import { getChannelMarkupValue } from 'lib/channelMarkup/channelMarkup'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { determineMemberPricing, onlyNonZeroFees } from 'lib/offer/pricing'
import offerUrgencyLabelMap from './offerUrgencyLabelMap'
import { OfferUnavailableReason } from 'lib/search/constants'

type ContractSchema = components['schemas']
type RoomRate = ContractSchema['leHotelRoomRate']
type RatePlan = ContractSchema['ratePlan']
type LeHotelPackage = ContractSchema['leHotelPackage']
type LeHotelOption = ContractSchema['leHotelOption']
type LeHotelOffer = ContractSchema['leHotelOffer']

function trimToActiveSchedules(schedules: Array<App.ReservationSchedule>): Array<App.ReservationSchedule> {
  return schedules.filter((schedule) =>
    schedule.activePeriod.from &&
    schedule.activePeriod.to &&
    isDateBetweenOrEqual(schedule.activePeriod.from, schedule.activePeriod.to),
  )
}

export interface HotelMapperRoomInfo {
  partnership: Record<string, App.PackagePartnership | undefined>;
  roomType: Record<string, App.PackageRoomType | undefined>;
  roomRate: Record<string, App.PackageRoomRate | undefined>;
}

type GeoData = ContractSchema['geoData']
export function geoDataMap(geoData: GeoData): App.OfferPropertyGeoData {
  return {
    continentCode: geoData.continentCode,
    country: geoData.country,
    countryCode: geoData.countryCode,
    locality: geoData.locality,
    route: geoData.route,
    streetNumber: geoData.streetNumber,
    administrativeAreaLevel1: geoData.administrativeAreaLevel1,
    administrativeAreaLevel2: geoData.administrativeAreaLevel2,
    administrativeAreaLevel3: geoData.administrativeAreaLevel3,
    administrativeAreaLevel4: geoData.administrativeAreaLevel4,
    administrativeAreaLevel5: geoData.administrativeAreaLevel5,
    placeId: geoData.placeId,
  }
}

type Property = ContractSchema['property']
export function propertyMap(property: Property): App.OfferProperty {
  const mapped: App.OfferProperty = {
    id: property.id,
    name: property.name,
    address: property.address,
    latitude: property.location.latitude,
    longitude: property.location.longitude,
    timezone: property.timezone,
    timezoneOffset: property.timezoneOffset,
    logoImageId: property.logo.id,
    reviews: property.reviews ?? [],
    childrenPolicy: property.childrenPolicy,
    infantPolicy: 'Under 2 years old',
    geoData: geoDataMap(property.geoData),
    maxChildAge: (property.ageCategories.find(cat => cat.name === 'Adult')?.minimumAge ?? 18) - 1,
    maxInfantAge: (property.ageCategories.find(cat => cat.name === 'Child')?.minimumAge ?? 3) - 1,
    taxesAndFeesContent: property.taxAndFeesContent,
    taxesPayableAtProperty: property.taxesPayableAtProperty,
    useDynamicTaxesFees: property.useDynamicTaxesFees,
    useDynamicCancellationPolicies: property.useDynamicCancellationPolicies,
    useDynamicOccupancy: property.useDynamicOccupancy,
    currentRegionMarketedLos: property.currentRegionMarketedLos,
    isUltraLux: property.subCategory === 'Ultra Lux',
    category: property.category ?? '',
    hideChildPrices: property.hideChildPrices,
    chainId: property.chainId,
  }

  if (property.rating) {
    mapped.rating = {
      score: property.rating,
      reviewsTotal: property.reviewsTotal ?? 0,
      reviewsSource: property.reviewsSource,
    }
  }

  return mapped
}

type Schedule = ContractSchema['availabilitySchedule']
export function mapOfferSchedule(schedule: Schedule): App.OfferSchedule {
  return {
    start: schedule.start,
    end: schedule.end,
  }
}

type ReserveForZero = LeHotelOffer['reserveForZero']
export function mapReserveForZero(reserveForZero: ReserveForZero): { minDays: number } {
  return {
    minDays: reserveForZero!.min_days,
  }
}

type Amenity = ContractSchema['amenity']
type AmenityGroup = ContractSchema['amenityGroup']
function roomAmenityMap(
  amenity: Amenity,
  group: AmenityGroup,
  index: number,
): App.PackageRoomAmenity {
  return {
    id: `${group.name}-${index}`,
    description: amenity.name,
    group: group.name,
    symbol: determineOfferFeatureSymbol(amenity.name),
  }
}

type RoomType = ContractSchema['roomType']
export function mapRoomType(
  serverRoom: RoomType,
): App.PackageRoomType {
  return {
    id: serverRoom.id,
    name: serverRoom.name,
    images: serverRoom.images.map(imageMap),
    description: serverRoom.description,
    amenities: sortBy(
      serverRoom.amenityGroups.map(group => group.values.map((amenity, index) => roomAmenityMap(amenity, group, index))).flat(),
      (amenity) => amenity.description,
      'asc',
    ),
    additionalGuestAmountDescription: serverRoom.additionalGuestAmountDescription,
    sizeSqm: serverRoom.sizeSqm ?? undefined,
    numberOfBathrooms: serverRoom.numberOfBathrooms ?? undefined,
    numberOfBedrooms: serverRoom.numberOfBedrooms ?? undefined,
    group: serverRoom.group ?? undefined,
    stats: {
      booked: serverRoom.stats?.purchaseCount ?? 0,
    },
  }
}

export function mapRoomRate(
  serverRoomRate: RoomRate,
  serverRatePlan: RatePlan,
): App.PackageRoomRate {
  return {
    id: serverRoomRate.id,
    ratePlanId: serverRatePlan.id,
    cancellationPolicy: {
      type: serverRatePlan.cancellationPolicy.type,
      policyDetail: serverRatePlan.cancellationPolicy.description,
    },
    extraGuestSurcharges: serverRoomRate.extraGuestSurcharges,
    group: serverRatePlan.group ?? undefined,
    isPackaged: serverRatePlan.isPackaged,
    packagedRatePlanId: serverRatePlan.packagedRateId,
    capacities: serverRoomRate.capacities,
    includedGuests: serverRoomRate.includedGuests,
    inclusionsHideValue: serverRoomRate.inclusionsHideValue,
    isBaseRate: !!serverRatePlan.isBaseRate,
    isReservableForZero: serverRoomRate?.isReservableForZero ?? false,
    schedules: serverRatePlan.schedules,
    hasTactical: serverRoomRate.hasTactical,
    cancellationPolicies: serverRoomRate.cancellationPolicies,
    marginPercentage: serverRoomRate.marginPercentage,
    mx: serverRoomRate.mx,
  }
}

/**
 * Try work out a category for the inclusion based on the description
 * Note: This is not fully implemented, only tries to determine board code inclusions at the moment
 * @param description The description of the inclusion
 */
function getInclusionDescriptionCategory(description: string): App.PackageInclusionCategory | undefined {
  const normalised = description.toLowerCase().replaceAll(' ', '')
  if (normalised.includes('dailybreakfast')) {
    return 'Breakfast'
  } else if (normalised.includes('lunch ')) {
    return 'Lunch'
  }
}

export function getRoomRateInclusions(
  serverRoomRate: RoomRate,
  duration: number,
  inclusionType: 'common' | 'bonus' | 'luxPlus',
): Array<App.PackageInclusion> {
  if (!serverRoomRate.inclusions.length) return []

  return serverRoomRate.inclusions.filter((inclusion) => {
    const hasValidDuration = (inclusion.minDuration > 0 && duration >= inclusion.minDuration) && (inclusion.maxDuration > 0 && duration <= inclusion.maxDuration)
    const isCommon = inclusionType === 'common' && !inclusion.isBonus && !inclusion.luxPlusTier
    const isBonus = inclusionType === 'bonus' && inclusion.isBonus && !inclusion.luxPlusTier
    const isLuxPlus = inclusionType === 'luxPlus' && !!inclusion.luxPlusTier

    return hasValidDuration && (isCommon || isBonus || isLuxPlus)
  }).map<App.PackageInclusion>((incl) => {
    const description = incl.description ? getOfferInclusionDescriptionFromMarkdown(incl.description) : ''
    return {
      id: uuidV4(),
      isBonus: incl.isBonus,
      maxDuration: incl.maxDuration,
      minDuration: incl.minDuration,
      schedules: incl.schedules,
      symbol: determineOfferFeatureSymbol(incl.categoryIcon || description),
      description,
      displayContext: 'Rate',
      category: getInclusionDescriptionCategory(description),
      hasTactical: trimToActiveSchedules(incl.schedules).some(schedule => schedule.type === 'tactical'),
      luxPlusTier: incl.luxPlusTier as App.MembershipSubscriptionTier,
      type: incl.type ?? undefined,
    }
  })
}

function getHotelBonusInclusions(
  serverPkg: LeHotelPackage,
  serverRoomRate: RoomRate,
  ratePlan: RatePlan,
  duration: number,
): Array<App.PackageInclusion> {
  const roomBonusInclusions = getRoomRateInclusions(serverRoomRate, duration, 'bonus')

  const packageBonusInclusionsForDuration = serverPkg.inclusions.bonus.find(inclusion => inclusion.fromNights <= duration && inclusion.toNights >= duration)
  if (packageBonusInclusionsForDuration?.contentV2) {
    return [
      ...roomBonusInclusions,
      ...packageBonusInclusionsForDuration.contentV2
        .map<App.PackageInclusion>(item => ({
          id: uuidV4(),
          description: item.text,
          symbol: determineOfferFeatureSymbol(item.icon || item.text),
          isHighlighted: item.isHighlighted,
          minDuration: 1,
          maxDuration: MAX_STAY,
          isBonus: true,
          displayContext: 'Package',
          schedules: [],
          hasTactical: false,
          category: getInclusionDescriptionCategory(item.text),
        })),
    ]
  } else if (packageBonusInclusionsForDuration?.content) {
    return [
      ...roomBonusInclusions,
      ...getOfferInclusionDescriptionsListFromMarkdown(packageBonusInclusionsForDuration.content)
        .map<App.PackageInclusion>(item => ({
          id: uuidV4(),
          description: item,
          minDuration: 1,
          maxDuration: MAX_STAY,
          isBonus: true,
          displayContext: 'Package',
          schedules: [],
          hasTactical: false,
          category: getInclusionDescriptionCategory(item),
          symbol: determineOfferFeatureSymbol(item),
        })),
    ]
  }

  const ratePlanBonusInclusionsForDuration = ratePlan.inclusions.bonus.find(inclusion => inclusion.fromNights <= duration && inclusion.toNights >= duration)
  if (ratePlanBonusInclusionsForDuration) {
    if (ratePlanBonusInclusionsForDuration.contentV2) {
      return [
        ...roomBonusInclusions,
        ...ratePlanBonusInclusionsForDuration.contentV2.map<App.PackageInclusion>(i => ({
          id: uuidV4(),
          description: i.text,
          symbol: determineOfferFeatureSymbol(i.icon || i.text),
          isHighlighted: i.isHighlighted,
          minDuration: 1,
          maxDuration: MAX_STAY,
          isBonus: true,
          displayContext: 'RatePlan',
          schedules: [],
          hasTactical: false,
          category: getInclusionDescriptionCategory(i.text),
        })),
      ]
    }
    if (ratePlanBonusInclusionsForDuration.content) {
      return [
        ...roomBonusInclusions,
        ...getOfferInclusionDescriptionsListFromMarkdown(ratePlanBonusInclusionsForDuration.content).map<App.PackageInclusion>(i => ({
          id: uuidV4(),
          description: i,
          minDuration: 1,
          maxDuration: MAX_STAY,
          isBonus: true,
          displayContext: 'RatePlan',
          schedules: [],
          hasTactical: false,
          category: getInclusionDescriptionCategory(i),
          symbol: determineOfferFeatureSymbol(i),
        })),
      ]
    }
  }

  return roomBonusInclusions
}

function getLuxPlusInclusionsByTier(
  serverRoomRate: RoomRate,
  duration: number,
): App.LuxPlusInclusionsByTier | undefined {
  const luxPlusRoomRateInclusions = getRoomRateInclusions(serverRoomRate, duration, 'luxPlus')

  if (!luxPlusRoomRateInclusions.length) return

  const groupedInclusions = groupBy(luxPlusRoomRateInclusions, inclusion => inclusion.luxPlusTier)
  return Object.fromEntries(groupedInclusions) as Record<App.MembershipSubscriptionTier, Array<App.PackageInclusion>>
}

type SeverPkg = LeHotelOffer['packages'][string]

function getLuxPlusInclusionsV2ByTier(
  inclusions?: SeverPkg['inclusionsV2'],
): App.LuxPlusInclusionsByTier | undefined {
  const inclusionsWithTier = inclusions?.filter(inclusion => inclusion.lux_plus_tier)
  if (!inclusionsWithTier?.length) return

  const groupedInclusions = groupBy(
    inclusionsWithTier,
    inclusion => inclusion.lux_plus_tier as App.MembershipSubscriptionTier,
    (externalInclusion): App.PackageInclusion => {
      return {
        id: externalInclusion.id ?? uuidV4(),
        isBonus: externalInclusion.style === 'bonus',
        isHighlighted: externalInclusion.style === 'highlighted',
        description: externalInclusion.inclusion!.name,
        // @ts-expect-error the contract is incorrect!
        symbol: determineOfferFeatureSymbol(externalInclusion.inclusion?.category.icon),
        // @ts-expect-error the contract is incorrect!
        parentCategory: externalInclusion.inclusion?.category?.category,
        // @ts-expect-error the contract is incorrect!
        category: externalInclusion.inclusion?.category?.name,
        luxPlusTier: externalInclusion.lux_plus_tier as App.MembershipSubscriptionTier,
      }
    },
  )
  return Object.fromEntries(groupedInclusions) as Record<App.MembershipSubscriptionTier, Array<App.PackageInclusion>>
}

function mapInclusionsV2(packageInclusions?: SeverPkg['inclusionsV2']): Array<App.PackageInclusion> | undefined {
  return packageInclusions?.filter(packageInclusion => !packageInclusion.lux_plus_tier).map<App.PackageInclusion>((packageInclusion) => {
    return {
      id: packageInclusion.id ?? uuidV4(),
      isBonus: packageInclusion.style === 'bonus',
      isHighlighted: packageInclusion.style === 'highlighted',
      description: packageInclusion.inclusion?.name ?? '',
      // @ts-expect-error the contract is incorrect!
      symbol: determineOfferFeatureSymbol(packageInclusion.inclusion?.category?.icon),
      // @ts-expect-error the contract is incorrect!
      parentCategory: packageInclusion.inclusion?.category?.category,
      // @ts-expect-error the contract is incorrect!
      category: packageInclusion.inclusion?.category?.name ?? getInclusionDescriptionCategory(packageInclusion.inclusion?.name ?? ''),
      luxPlusTier: packageInclusion.lux_plus_tier as App.MembershipSubscriptionTier,
    }
  })
}

type PackagePartnership = ContractSchema['pkgPartnership']
export function packagePartnershipMap(serverPartnerships?: Array<PackagePartnership>): App.PackagePartnership | undefined {
  const firstPartnership = serverPartnerships?.[0]

  if (firstPartnership) {
    return {
      bonusPoints: firstPartnership.bonusPoints ?? 0,
      bonusDescription: firstPartnership.bonusDescription || '',
      localRewardConversionRate: firstPartnership.localRewardConversionRate ?? 1,
    }
  }
}

export function getHotelInclusions(
  serverPkg: LeHotelPackage,
  serverRoomRate: RoomRate,
  serverRatePlan: RatePlan,
  duration: number,
): Array<App.PackageInclusion> {
  const roomInclusions = getRoomRateInclusions(serverRoomRate, duration, 'common')

  if (serverPkg.inclusions.highlights) {
    return [...roomInclusions, {
      id: uuidV4(),
      description: serverPkg.inclusions.highlights,
      symbol: determineOfferFeatureSymbol(serverPkg.inclusions.highlights),
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: false,
      displayContext: 'Package',
      schedules: [],
      hasTactical: false,
      category: getInclusionDescriptionCategory(serverPkg.inclusions.highlights),
    }]
  }

  if (serverPkg.inclusions.description) {
    return [...roomInclusions, {
      id: uuidV4(),
      description: serverPkg.inclusions.description,
      symbol: determineOfferFeatureSymbol(serverPkg.inclusions.description),
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: false,
      displayContext: 'Package',
      schedules: [],
      hasTactical: false,
      category: getInclusionDescriptionCategory(serverPkg.inclusions.description),
    }]
  }

  if (serverRatePlan.inclusions.descriptionV2?.length) {
    return [
      ...roomInclusions,
      ...serverRatePlan.inclusions.descriptionV2.map<App.PackageInclusion>(i => ({
        id: uuidV4(),
        description: i.text,
        symbol: determineOfferFeatureSymbol(i.icon || i.text),
        isHighlighted: i.isHighlighted,
        minDuration: 1,
        maxDuration: MAX_STAY,
        isBonus: false,
        displayContext: 'RatePlan',
        schedules: [],
        hasTactical: false,
        category: getInclusionDescriptionCategory(i.text),
      })),
    ]
  }
  if (serverRatePlan.inclusions.description) {
    return [
      ...roomInclusions,
      ...getOfferInclusionDescriptionsListFromMarkdown(serverRatePlan.inclusions.description).map<App.PackageInclusion>(i => ({
        id: uuidV4(),
        symbol: determineOfferFeatureSymbol(i),
        description: i,
        minDuration: 1,
        maxDuration: MAX_STAY,
        isBonus: false,
        displayContext: 'RatePlan',
        schedules: [],
        hasTactical: false,
        category: getInclusionDescriptionCategory(i),
      })),
    ]
  }

  return roomInclusions
}

export function getStayPayItems(ratePlan: RatePlan, duration: number): Array<App.StayPayTier> {
  if (!ratePlan.stayPay) return []

  const validOffers = ratePlan.stayPay.filter(tier => tier.stayNights <= duration)

  const bestOffer = validOffers.reduce((maxOffer, currentOffer) =>
    currentOffer.freeNights > maxOffer.freeNights ? currentOffer : maxOffer, validOffers[0])

  const result: Array<App.StayPayTier> = []

  if (bestOffer) {
    result.push({
      stayNights: duration,
      freeNights: bestOffer.freeNights,
    })
  }

  return result
}

const boardCodeInclusionCategories = new Set<App.PackageInclusionCategory>(['Breakfast', 'Lunch', 'Dinner', 'All-Inclusive Dining'])

/**
 * Determines a board code based on the list of inclusions given
 * Board code is a defined set of codes that describe a list of inclusions for a rate
 * @param inclusions Set of inclusions to determine a board code from
 */
function getInclusionsBoardCode(inclusions: Array<App.PackageInclusion>): App.RateBoardCode {
  if (inclusions.length === 0) {
    // no inclusions, is room only
    return 'roomonly'
  } else if (inclusions.some(incl => incl.isBonus || incl.luxPlusTier)) {
    // any bonus inclusion or lux inclusions are special inclusions
    // and means this will never be a standardised board code
    return 'LE'
  } else {
    const inclusionsByCategory = groupBy(inclusions, incl => {
      if (incl.category && boardCodeInclusionCategories.has(incl.category)) {
        return incl.category
      }
      return 'other'
    })

    if (inclusionsByCategory.has('other')) {
      // We have inclusions other than known board code inclusions
      // thus, it's not a standard board code
      return 'LE'
    }

    const hasBreakfast = inclusionsByCategory.has('Breakfast')
    const hasLunch = inclusionsByCategory.has('Lunch')
    const hasDinner = inclusionsByCategory.has('Dinner')
    const isAllInclusive = inclusionsByCategory.has('All-Inclusive Dining')

    if (isAllInclusive) {
      return 'allinclusive'
    } else if (hasBreakfast && hasLunch && hasDinner) {
      return 'fullboard'
    } else if (hasBreakfast && (hasLunch || hasDinner)) {
      return 'halfboard'
    } else if (hasBreakfast) {
      return 'breakfast'
    }
    return 'LE'
  }
}

export function mapHotelPackageOption({
  serverOption,
  serverLoyaltyTargetUpgradeOption,
  serverOffer,
  roomInfo,
  channelMarkup,
}: {
  serverOption: LeHotelOption,
  serverLoyaltyTargetUpgradeOption?: LeHotelOption,
  serverOffer: LeHotelOffer,
  roomInfo: HotelMapperRoomInfo,
  channelMarkup?: App.ChannelMarkup
}): App.HotelPackage {
  const markup = getChannelMarkupValue(serverOffer.id, serverOffer.type, channelMarkup)
  const serverPkg = serverOffer.packages[serverOption.fkPackageId]
  const ratePlan = serverOffer.ratePlans[serverOption.fkRatePlanId]
  const roomRate = serverOffer.roomRates[serverOption.fkRoomRateId]

  const inclusions = (serverPkg.inclusionsV2 ? mapInclusionsV2(serverPkg?.inclusionsV2) : getHotelInclusions(serverPkg, roomRate, ratePlan, serverOption.duration)) ?? []
  const bonusInclusions = getHotelBonusInclusions(serverPkg, roomRate, ratePlan, serverOption.duration)
  const luxPlusInclusionsByTier = serverPkg.inclusionsV2 ? getLuxPlusInclusionsV2ByTier(serverPkg.inclusionsV2) : getLuxPlusInclusionsByTier(roomRate, serverOption.duration)

  const hasTactical = serverOption.hasTactical ?? roomInfo.roomRate[serverOption.fkRoomRateId]?.hasTactical
  const stayPayItems = getStayPayItems(ratePlan, serverOption.duration)

  const inclusionsAmount = serverOption?.inclusionsAmount ?? 0 // amount without LuxPlus+ inclusions
  const totalInclusionsAmount = inclusionsAmount ?? 0

  const allInclusions = [...inclusions, ...bonusInclusions, ...Object.values(luxPlusInclusionsByTier ?? {}).flat()]
  const boardCode = getInclusionsBoardCode(allInclusions)

  const propertyFees = serverOption.totals?.propertyFees ?? 0
  const fees = onlyNonZeroFees([
    { type: 'property', amount: propertyFees },
    { type: 'taxesAndFees', amount: serverOption.totals?.taxesAndFees ?? 0 },
    { type: 'surcharge', amount: serverOption.surcharge ?? 0 },
  ])

  const defaultPricing: App.PricingWithValue | undefined = serverOption.price && serverOption.value ? {
    price: serverOption.price + propertyFees,
    value: serverOption.value,
    discountPercent: getValueOffPercent(serverOption.value, serverOption.price + propertyFees),
    saleUnit: serverOffer.saleUnit,
    fees,
  } : undefined

  const memberPricing = determineMemberPricing(
    defaultPricing,
    serverOption.luxPlusPrice ? serverOption.luxPlusPrice + propertyFees : defaultPricing?.price,
    serverOption.luxPlusBaseValue,
  )

  const serverLoyaltyUpgradePackage = serverPkg.targetUpgradePackageId ?
    Object.values(serverOffer.packages).find(p => p.lePackageId === serverPkg.targetUpgradePackageId) :
    undefined

  return {
    id: serverPkg.id ?? serverPkg.lePackageId,
    lePackageId: serverPkg.lePackageId,
    offerId: serverPkg.fkOfferId,
    description: serverPkg.inclusions.description || ratePlan.inclusions.description,
    sortOrder: serverPkg.sortOrder,
    roomOccupancy: serverPkg.includedGuestsLabel,
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    duration: serverOption.duration,
    price: (serverOption.price ?? 0) * markup,
    memberPrice: serverOption.luxPlusPrice ?? 0,
    value: serverOption.value ?? 0,
    memberValue: serverOption.luxPlusBaseValue ?? 0,
    marginAud: serverOption.marginAud,
    taxesAndFees: (serverOption.totals?.taxesAndFees ?? 0) * markup,
    propertyFees: serverOption.totals?.propertyFees ?? 0,
    surcharge: serverOption?.surcharge ?? 0,
    trackingPrice: serverOption.trackingPrice,
    name: serverOption.name ?? serverPkg.name,
    partnership: roomInfo.partnership[serverOption.fkPackageId],
    roomPolicyDescription: serverPkg.copy.roomPolicyDescription,
    bundleDiscountPercent: ratePlan.bundleDiscount,
    discountPercent: ratePlan.discount,
    shouldInstantPurchase: ratePlan.shouldInstantPurchase,
    inclusions,
    inclusionsAmount,
    totalInclusionsAmount,
    isBaseRate: serverOption.isBaseRate,
    bonusInclusions,
    luxPlusInclusionsByTier,
    durationLabel: pluralizeToString('Night', serverOption.duration),
    uniqueKey: getPackageUniqueKey(serverPkg.id ?? serverPkg.lePackageId, serverOption.duration, serverOption.fkRoomRateId),
    roomType: roomInfo.roomType[serverOption.fkRoomTypeId]!,
    roomRate: roomInfo.roomRate[serverOption.fkRoomRateId]!,
    allowBuyNowBookLater: serverPkg.allowBuyNowBookLater,
    allowDatesRequest: serverPkg.allowDatesRequest,
    hasTactical,
    availableRooms: serverOption.availableRooms,
    ignoreDynamicCancellationPolicy: ratePlan.ignoreDynamicCancellationPolicy,
    stayPay: stayPayItems,
    boardCode,
    defaultPricing,
    memberPricing,
    mx: serverPkg.mx,
    luxLoyalty: {
      targetUpgradePackageUniqueKey: (serverLoyaltyTargetUpgradeOption && serverLoyaltyUpgradePackage) ? getPackageUniqueKey(
        serverLoyaltyUpgradePackage.id ?? serverLoyaltyUpgradePackage.lePackageId,
        serverOption.duration,
        serverLoyaltyTargetUpgradeOption.fkRoomRateId,
      ) : undefined,
    },
  }
}

export function mapTileInclusions(
  inclusions?: LeHotelOffer['inclusions']['tileInclusions'],
): Array<App.OfferInclusion> | undefined {
  return inclusions?.map<App.OfferInclusion>((inclusion) => ({
    id: uuidV4(),
    description: inclusion.text,
    symbol: determineOfferFeatureSymbol(inclusion.icon ?? inclusion.text),
    isHighlighted: inclusion.isHighlighted,
    luxPlusTier: inclusion.luxPlusTier as App.MembershipSubscriptionTier,
  }))
}

function getLuxPlusTileInclusions(inclusions?: Array<App.OfferInclusion>) {
  return inclusions?.filter(inclusion => inclusion.luxPlusTier)
}

export function getTileInclusions(inclusions?: Array<App.OfferInclusion>) {
  return inclusions?.filter(inclusion => !inclusion.luxPlusTier)
}

export function hotelOfferMap(serverOffer: LeHotelOffer, regionCode: string, channelMarkup?: App.ChannelMarkup): App.HotelOffer {
  const offerType = serverOffer.type
  const typeConfig = offerTypeConfig[offerType]!
  const offerTypeHotel = offerType === OFFER_TYPE_HOTEL
  const hasBNBLPackages = offerTypeHotel && Object.values(serverOffer.packages).some((p) => (p.allowBuyNowBookLater))

  const mappedTileInclusions = mapTileInclusions(serverOffer.inclusions.tileInclusions)

  const luxPlusTileInclusions = getLuxPlusTileInclusions(mappedTileInclusions)
  const tileInclusions = getTileInclusions(mappedTileInclusions)

  const offer: App.HotelOffer = {
    type: typeConfig.type,
    productType: typeConfig.productType,
    walledGarden: typeConfig.walledGarden,
    hasBuyNowBookLater: typeConfig.hasBuyNowBookLater && !serverOffer.bundledWithFlightsOnly,
    hasBuyNowBookLaterPackages: hasBNBLPackages,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    overlayImageLabel: typeConfig.overlayImageLabel,
    analyticsType: typeConfig.analyticsType,
    id: serverOffer.id,
    name: serverOffer.name,
    experiencesInFlow: serverOffer.experiencesInFlow,
    experiencesCurated: serverOffer.experiencesCurated,
    showOnlyExperiencesCurated: serverOffer.showOnlyExperiencesCurated,
    location: serverOffer.location.description,
    locations: serverOffer.tags.location ?? [],
    description: serverOffer.copy.description ?? '',
    highlights: serverOffer.copy.highlights ?? '',
    holidayTypes: serverOffer.tags.holidayTypes ?? [],
    whatWeLike: serverOffer.copy.whatWeLike,
    facilities: serverOffer.copy.facilities,
    finePrint: serverOffer.copy.finePrint,
    gettingThere: serverOffer.copy.gettingThere,
    insuranceCountries: serverOffer.insurance.countries,
    isSoldOut: serverOffer.isSoldOut ?? false,
    bookByDate: serverOffer.schedules?.bookBy?.end!,
    travelToDate: serverOffer.schedules?.travelBy?.end!,
    slug: serverOffer.slug,
    canEarnPartnershipPoints: serverOffer.partnerships.length > 0,
    durationLabel: serverOffer.durationLabel ?? '',
    tileDurationLabel: serverOffer.durationLabel ?? '',
    images: serverOffer.images.map(imageMap),
    image: imageMap(serverOffer.images[0]),
    visibilitySchedule: serverOffer.schedules?.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
    onlinePurchaseSchedule: serverOffer.schedules?.onlinePurchase ? mapOfferSchedule(serverOffer.schedules.onlinePurchase) : undefined,
    availabilitySchedule: serverOffer.schedules?.availability ? mapOfferSchedule(serverOffer.schedules.availability) : undefined,
    luxPlusSchedule: serverOffer.schedules?.luxPlus ? mapOfferSchedule(serverOffer.schedules.luxPlus) : undefined,
    vimeoVideoId: serverOffer.video?.id,
    isVideoVimeoHero: serverOffer.video?.isVideoVimeoHero,
    locationHeading: serverOffer.location.heading,
    locationSubheading: serverOffer.location.subheading,
    noIndex: serverOffer.noIndex,
    saleUnit: serverOffer.saleUnit,
    saleUnitLong: saleUnitLongReplacements[serverOffer.saleUnit] ?? serverOffer.saleUnit,
    flightPrices: serverOffer.flights?.[0]?.prices ?? {},
    flightDestinationPort: serverOffer.flights?.[0]?.destinationCode,
    flightsMaxArrivalTime: serverOffer.flights?.[0]?.latestDestinationArrivalTime,
    flightsMinReturningDepartureTime: serverOffer.flights?.[0]?.earliestDestinationDepartureTime,
    flightCacheDisabled: serverOffer.flights?.[0]?.cacheDisabled,
    flightsWarningHeadline: serverOffer.flights?.[0]?.warning?.heading,
    flightsWarningPopupBody: serverOffer.flights?.[0]?.warning?.description,
    urgencyTags: offerUrgencyLabelMap(serverOffer),
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    exclusiveExtrasLarge: serverOffer.inclusions.description,
    inclusions: serverOffer.inclusions.description,
    inclusionsHeading: serverOffer.inclusions.heading,
    additionalDescription: serverOffer.copy.additionalDescription ?? '',
    luxPlusTileInclusions,
    tileInclusions,
    tileInclusionsHeading: serverOffer.inclusions.tileHeading,
    daysBeforeCheckInChangesDisallowed: serverOffer.daysBeforeCheckInChangesDisallowed ?? 0,
    flightsWarningEnabled: !!serverOffer.flights?.[0]?.warning,
    offerFlightsEnabled: config.FLIGHT_ENABLED && (serverOffer.flights?.length ?? 0) > 0,
    badge: serverOffer.badge,
    bundledWithFlightsOnly: serverOffer.bundledWithFlightsOnly,
    whitelistedCarrierCodes: serverOffer.whitelistedCarrierCodes ?? [],
    disableDeposit: !config.DEPOSITS_ENABLED || serverOffer.disableDeposit,
    depositThresholds: serverOffer.depositThresholds,
    packages: [],
    defaultOptions: [],
    isPartnerProperty: serverOffer.isPartnerProperty,
    numberOfDateChangesAllowed: serverOffer.numberOfDateChanges ?? 'Unlimited',
    packageUpgradesAllowed: offerTypeHotel && serverOffer.packageUpgradesAllowed,
    disableBestPriceGuarantee: serverOffer.disableBestPriceGuarantee,
    minDuration: Math.min(...serverOffer.options.map(o => o.duration)),
    ...(serverOffer.reserveForZero && { reserveForZero: mapReserveForZero(serverOffer.reserveForZero) }),
    ...((offerTypeHotel || serverOffer.type === OFFER_TYPE_ALWAYS_ON) && serverOffer.forceBundleId && { forceBundleId: serverOffer.forceBundleId }),
    vendorName: serverOffer.vendorName,
    vendorBookingEmail: serverOffer.vendorBookingEmail,
    vendorContactPhone: serverOffer.vendorContactPhone,
    property: propertyMap(serverOffer.property),
    hasHiddenCancellationPolicy: Object.values(serverOffer.ratePlans).some(r => r.cancellationPolicy?.type === 'hidden-cancellation-policy'),
    isDiscountPillHidden: serverOffer.isDiscountPillHidden ?? false,
    luxPlus: {
      hasMemberInclusions: serverOffer.luxPlus.hasMemberInclusions,
      restrictPurchaseToMembers: serverOffer.luxPlus.restrictPurchaseToMembers,
      access: serverOffer.luxPlus.access,
      hasMemberPrices: serverOffer.options.some(option => !!option.luxPlusPrice),
    },
    luxLoyalty: {
      hasUpgradablePackages: false,
    },
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(serverOffer.isAgentHubExclusive),
    stats: {
      booked: serverOffer.stats?.purchaseCount ?? 0,
      views: serverOffer.stats?.viewCount ?? 0,
    },
    isBeachBreakOffer: !!serverOffer.tags.campaigns.find(campaign => campaign === 'EBB25'),
  }

  const roomInfo = serverOffer.options.reduce<HotelMapperRoomInfo>((acc, serverOption) => {
    if (!acc.partnership[serverOption.fkPackageId]) {
      acc.partnership[serverOption.fkPackageId] = packagePartnershipMap(serverOffer.packages[serverOption.fkPackageId].partnerships)
    }
    if (!acc.roomType[serverOption.fkRoomTypeId]) {
      acc.roomType[serverOption.fkRoomTypeId] = mapRoomType(serverOffer.roomTypes[serverOption.fkRoomTypeId])
    }
    if (!acc.roomRate[serverOption.fkRoomRateId]) {
      acc.roomRate[serverOption.fkRoomRateId] = mapRoomRate(serverOffer.roomRates[serverOption.fkRoomRateId], serverOffer.ratePlans[serverOption.fkRatePlanId])
    }
    return acc
  }, { roomType: {}, roomRate: {}, partnership: {} })

  offer.packages = serverOffer.options.map(option => {
    const serverOptionPackage = serverOffer.packages[option.fkPackageId]
    const serverLoyaltyTargetUpgradeOption = serverOptionPackage.targetUpgradePackageId ? serverOffer.options.find(serverOption => {
      const serverPackage = serverOffer.packages[serverOption.fkPackageId]
      if (!serverPackage) return false
      return serverPackage.lePackageId === serverOptionPackage.targetUpgradePackageId
    }) : undefined

    return mapHotelPackageOption({
      serverOption: option,
      serverLoyaltyTargetUpgradeOption,
      serverOffer,
      roomInfo,
      channelMarkup,
    })
  })
  offer.luxLoyalty.hasUpgradablePackages = offer.packages.some(pkg => !!pkg.luxLoyalty.targetUpgradePackageUniqueKey)
  offer.hasTactical = offer.packages.some(p => p.hasTactical && p.price)
  // Generating all unique options keys
  offer.defaultOptions = serverOffer.lowestOptions.map((o) => getPackageUniqueKey(o.fkPackageId, o.duration, o.fkRoomRateId))
  if (offer.hasTactical && !offer.packages.some((o) => o.hasTactical && offer.defaultOptions.includes(o.uniqueKey))) {
    const defaultTacticalOptions = offer.packages.filter((o) => o.hasTactical && o.price).map(o => o.uniqueKey)
    offer.defaultOptions = defaultTacticalOptions.length > 0 ? defaultTacticalOptions : offer.defaultOptions
  }

  offer.isDomestic = isOfferDomestic(offer, regionCode)
  offer.lowestPricePackage = getLowestPricePackage(offer, offer.packages) as App.HotelPackage

  if (!offer.lowestPricePackage) {
    throw new Error('Invalid offer error, missing lowest price package that should always exist.')
  }

  // duration label of 1 to x nights is not very useful for users, just show a from price
  offer.tileDurationLabel = offer.packages.some(p => p.duration === 1) ? '' : offer.durationLabel

  if (offer.property?.isUltraLux) {
    offer.productType = 'ultralux_hotel'
    offer.walledGarden = false
  }
  offer.walledGarden = checkGatedOffer(offer) || offer.walledGarden

  return offer
}

type GetHotelListResultItem = Exclude<paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json']['result'][0], string>
function mapSearchResultSuggestedTravelDateToOfferListMetaDataTravelDates(
  suggestedTravelDate: GetHotelListResultItem['suggestedTravelDates'],
  type: GetHotelListResultItem['type'],
  offerId: string,
  channelMarkup?: App.ChannelMarkup,
): App.OfferListMetaData['suggestedTravelDates'] {
  if (suggestedTravelDate) {
    const markup = getChannelMarkupValue(offerId, type, channelMarkup)
    return {
      checkIn: suggestedTravelDate.checkIn,
      checkOut: suggestedTravelDate.checkOut,
      price: suggestedTravelDate.price ? suggestedTravelDate.price * markup : undefined,
      memberPrice: type === OFFER_TYPE_HOTEL ? suggestedTravelDate.luxPlusPrice : 0,
      currency: suggestedTravelDate.currency,
      packageId: suggestedTravelDate.packageId,
      value: suggestedTravelDate.value,
      roomRateId: suggestedTravelDate.roomRateId,
    }
  }
}

export function mapCancellationPolicyInfo(serverResult: GetHotelListResultItem): App.OfferListMetaData['cancellationPolicyInfo'] | undefined {
  if (serverResult.cancellationPolicyInfo) {
    return {
      refundable: serverResult.cancellationPolicyInfo.refundable,
      partiallyRefundable: serverResult.cancellationPolicyInfo.partiallyRefundable,
      nonRefundableDateRanges: serverResult.cancellationPolicyInfo.nonRefundableDateRanges,
      cancellationPolicies: serverResult.cancellationPolicyInfo.cancellationPolicies.map(policy => ({
        ...policy,
        percent: policy.percent ?? undefined,
        amount: policy.amount ?? undefined,
        nights: policy.nights ?? undefined,
      })),
    }
  }

  return undefined
}

export function mapSearchResultToOfferListMetaData(serverResult: GetHotelListResultItem, index: number, channelMarkup?: App.ChannelMarkup): App.OfferListMetaData {
  const markup = getChannelMarkupValue(serverResult.id, serverResult.type, channelMarkup)
  return {
    kind: serverResult.kind,
    offerId: serverResult.id,
    propertyId: serverResult.propertyId,
    bundledOfferId: serverResult.bundledOfferId,
    type: serverResult.type,
    distance: serverResult.distance,
    available: serverResult.available,
    listPosition: index,
    unavailableReason: serverResult.unavailableReason,
    packages: serverResult.packages,
    suggestedTravelDates: serverResult.suggestedTravelDates ? mapSearchResultSuggestedTravelDateToOfferListMetaDataTravelDates(serverResult.suggestedTravelDates, serverResult.type, serverResult.id, channelMarkup) : undefined,
    hasPromotions: serverResult.hasPromotions,
    location: serverResult.location,
    hiddenByDefault: serverResult.hiddenByDefault,
    pricing: {
      lowestPrice: serverResult.lowestPrice ? serverResult.lowestPrice * markup : undefined,
      lowestMemberPrice: serverResult.lowestMemberPrice,
      lowestPriceTaxes: serverResult.lowestPriceTaxes ? serverResult.lowestPriceTaxes * markup : undefined,
      lowestPricePropertyFees: serverResult.lowestPricePropertyFees,
      lowestPriceValue: serverResult.lowestPriceValue,
      lowestPricePackageId: serverResult.lowestPricePackageId,
      lowestPriceRoomRateId: serverResult.lowestPriceRoomRateId,
      lowestPriceMobilePromotion: serverResult.lowestPriceMobilePromotion,
      duration: serverResult.duration,
      hasLuxPlusRate: serverResult.hasLuxPlusRate,
      mx: serverResult.mx,
    },
    extendedAvailability: serverResult.extendedAvailability,
    cancellationPolicyInfo: mapCancellationPolicyInfo(serverResult),
    availableRooms: serverResult.availableRooms,
    hidePricing: !!(serverResult.available && serverResult.unavailableReason === OfferUnavailableReason.NO_PRICES_FOUND), // this is a special case where the offer is available, but we don't have pricing
  }
}

export const transformFilterObject = (input: Record<string, { count: number }>): Record<string, number> => {
  return Object.keys(input).reduce<Record<string, number>>((acc, key) => {
    acc[key] = input[key].count
    return acc
  }, {})
}
