import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from './accommodation'
import { getBookingProtectionItemsView } from './bookingProtection'
import { getCarHireItemsView } from './carHire'
import { getFlightItemsView } from './flights'
import { getLuxPlusSubscriptionItemView, getSubscriptionJoinItemView } from './luxPlusSubscription'
import { getVillaViews } from './villa'
import getLuxLoyaltyLoungePassItemViews from './getLuxLoyaltyLoungePassItemViews'
import getExperienceItemsView from './getExperienceItemsView'
import getTransferItemsView from './getTransferItemsView'
import getTourV2ExperienceItemsViews from './getTourV2ExperienceItemsViews'

function mapAnyItemViewWithStatus(hasRequiredData: boolean) {
  return (itemView: App.Checkout.AnyItemView): [string, App.WithDataStatus<App.Checkout.AnyItemView>] => {
    return [
      itemView.item.itemId,
      {
        hasRequiredData,
        data: itemView,
      },
    ] as const
  }
}

const getAllItemViews = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => getTransferItemsView(state),
  (state: App.State) => getBookingProtectionItemsView(state),
  (state: App.State) => getVillaViews(state),
  (state: App.State) => getCarHireItemsView(state),
  (state: App.State) => getSubscriptionJoinItemView(state),
  (state: App.State) => getTourV2ExperienceItemsViews(state),
  (state: App.State) => getLuxPlusSubscriptionItemView(state),
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (
    accommodationOfferViews,
    experienceItemViews,
    ...rest
  ): App.WithDataStatus<Map<string, App.WithDataStatus<App.Checkout.AnyItemView>>> => {
    const views: Array<[string, App.WithDataStatus<App.Checkout.AnyItemView>]> = []

    views.push(...accommodationOfferViews.data.flatMap((offerView) => offerView.itemViews.map(mapAnyItemViewWithStatus(accommodationOfferViews.hasRequiredData))))
    // give the ticket-level item
    views.push(...experienceItemViews.data.flatMap(experienceView => experienceView.ticketViews.map(mapAnyItemViewWithStatus(experienceItemViews.hasRequiredData))))
    views.push(...rest.flatMap((view) => view.data.map(mapAnyItemViewWithStatus(view.hasRequiredData))))

    return {
      hasRequiredData: !!views.length && views.every(([, o]) => o.hasRequiredData),
      data: new Map(views),
    }
  },
)

export default getAllItemViews
