import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_VILLA,
  OFFER_TYPE_EXPERIENCE,
} from 'constants/offer'
import config from 'constants/config'
import request, { authOptions } from 'api/requestUtils'

import { logNewRelic } from 'services/newRelic'
import { templates } from '@luxuryescapes/lib-uri-templates'
import { reverseUpdateToBrandSpecificFilters, serialiseOccupancy } from 'lib/search/searchUtils'
import { hotelOfferMap, mapSearchResultToOfferListMetaData, transformFilterObject } from './mappers/hotelOfferMap'
import { tourV2OfferMap, tourV2OfferSummaryMap } from './mappers/TourV2/tourV2OfferMapper'
import { traderInformationMap } from './mappers/traderInformation'
import { bedBankOfferMap } from './mappers/bedbankOfferMap'
import qs from 'qs'
import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { paths, StreamingHotelSearchResultRequest } from '@luxuryescapes/contract-search'
import wait from 'lib/promise/wait'
import { arrayToObject, nonNullable, split } from 'lib/array/arrayUtils'
import { isTourV2OfferSummary } from 'lib/offer/offerTypes'
import { ANYWHERE_PLACE_ID, SEARCH_VERTICALS } from 'constants/search'
import { getCruiseSearchList, getCruiseOfferByIdType } from './cruises'
import { detectOfferTypeFromId } from 'lib/offer/offerUtils'
import { getTourSearchListByFacet } from './search'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { tourOfferMap } from './mappers/tourOfferMap'
import { villaOfferMap } from './mappers/villaOfferMap'
import { bundleOfferMap } from './mappers/bundleOfferMap'
import { getSearchSocket, globalSearchSocket } from 'search/initialiseSearchSocket'
import { ScrollRequest } from '@luxuryescapes/contract-search/types/sockets'
import { getExperienceById } from './experiences'
import { StreamingHotelSearchOffersResponse } from 'actions/OfferActions'

export type HotelSearchResultResponse = paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json']
export type TourSearchResultResponse = paths['/api/search/tour/v1/list']['get']['responses']['200']['content']['application/json']

export type SearchOffer = {
  kind: string;
  id: string;
  packages?: Array<string>;
  distance?: number;
  available?: boolean;
  suggestedTravelDates?: {
    /** Format: ISO8601 */
    checkIn: string;
    /** Format: ISO8601 */
    checkOut: string;
    price?: number;
    memberPrice: number;
    value?: number;
    currency?: string;
    packageId?: string;
  };
  hasPromotions?: boolean;
}

let offerFetchAccumulator: Array<string> = []
let offerFetchPromise: Promise<{ [offerId: string]: App.AnyOffer }> | undefined

/**
 * This function will get and return a single offer by ID
 * However, it won't always do this straight away or in a separate request
 * The function will 'accumulate' all offer requests over a period of time and then fetch them
 * all in one go - returning them individually to the appropriate function call
 * @param id The ID of the offer to fetch
 * @param region Regioncode for the fetch
 * @param flightOrigin Flight origin for the fetch
 * @returns A promise with the offer requested
 */
export function getAccumulatedOfferById(id: string, region: string, flightOrigin?: string, currentCurrency?: string, isSpoofed?: boolean, channelMarkup?: App.ChannelMarkup) {
  if (detectOfferTypeFromId(id) === 'cruise') {
    // cruises don't have a multi fetch version to accumulate
    return getOfferById(id, { region, flightOrigin, currentCurrency })
  }

  // TODO remove this when we implement fetch for multiple offers:
  // https://aussiecommerce.atlassian.net/browse/EXP-2369
  if (detectOfferTypeFromId(id) === 'experience') {
    // experiences don't have a multi fetch version to accumulate yet
    return getOfferById(id, { region, flightOrigin, currentCurrency, isExperience: true, channelMarkup })
  }

  offerFetchAccumulator.push(id)
  if (!offerFetchPromise) {
    // give it 32ms to accumulate offer calls - that's around two frames at 60 fps worth of time
    offerFetchPromise = wait(32).then(async() => {
      const offersToFetch = offerFetchAccumulator
      // reset our state in prep for next set of calls
      offerFetchAccumulator = []
      offerFetchPromise = undefined

      const offers = await getOffersById(offersToFetch, region, { flightOrigin, isSpoofed }, channelMarkup)

      const offersById = arrayToObject(offers, offer => isTourV2OfferSummary(offer) ? offer.summaryId : offer.id)
      return offersById
    })
  }

  return offerFetchPromise.then((offers) => offers[id])
}

interface GetOfferOptions {
  region: string,
  flightOrigin?: string,
  allPackages?: boolean,
  preview?: string,
  privateRequestKey?: string,
  currentCurrency?: string
  isCruise?: boolean,
  isSpoofed?: boolean,
  channelMarkup?: App.ChannelMarkup
  isExperience?: boolean,
  source?: string,
  offerDeepLink?: string,
}

export function getOfferById(
  id: string,
  { region, flightOrigin, allPackages = false, preview, privateRequestKey, currentCurrency, isCruise, isSpoofed, isExperience, channelMarkup, source, offerDeepLink }: GetOfferOptions,
): Promise<App.HotelOffer | App.TourOffer | App.BedbankOffer | App.CruiseOffer | App.Tours.TourV2Offer | App.BundleOffer | App.VillaOffer | App.ExperienceOffer> {
  // TODO: Remove this once we have support for cruise in the public offer - https://aussiecommerce.atlassian.net/browse/CRUZ-2932
  if (isCruise || detectOfferTypeFromId(id) === 'cruise') {
    return getCruiseOfferByIdType(id, region, currentCurrency)
  }

  if (isExperience || detectOfferTypeFromId(id) === 'experience') {
    return getExperienceById(id, { currentCurrency, currentRegionCode: region })
  }

  const uri = templates.offer.publicOffer.expand({ id })

  const params = qs.stringify({
    flightOrigin,
    region,
    allPackages,
    preview,
    privateRequestKey: privateRequestKey ?? undefined,
    source,
    offerDeepLink,
  })

  const includeCredentials = isSpoofed || !!offerDeepLink

  return request.get<App.ApiResponse<PublicOfferV2.Offer>>(`${uri}?${params}`, includeCredentials ? { credentials: 'include' } : {})
    .then(async response => {
      if (response.result.type === OFFER_TYPE_BED_BANK) {
        return bedBankOfferMap(response.result, source)
      } else if (response.result.type === OFFER_TYPE_TOUR_V2) {
        return tourV2OfferMap(response.result)
      } else if (response.result.type === 'bundle_and_save') {
        return bundleOfferMap(response.result, region, channelMarkup)
      } else if (response.result.type === 'tour') {
        return tourOfferMap(response.result)
      } else if (response.result.type === 'rental') {
        return villaOfferMap(response.result, region, channelMarkup)
      } else {
        return hotelOfferMap(response.result, region, channelMarkup)
      }
    })
}

interface GetOffersByIdParams {
  checkIn?: string;
  checkOut?: string;
  occupancies?: Array<App.Occupants>;
  flightOrigin?: string;
  preview?: string;
  currentCurrency?: string;
  isSpoofed?: boolean;
}

export async function getOffersById(
  offerIds: Array<string>,
  regionCode: string,
  params: GetOffersByIdParams = {},
  channelMarkup?: App.ChannelMarkup,
): Promise<Array<App.CruiseOffer | App.Offer | App.BedbankOffer | App.Tours.TourV2OfferSummary | App.ExperienceOffer>> {
  const uri = templates.offer.publicOffers.expand({})

  const baseQuery = {
    checkIn: params.checkIn,
    checkOut: params.checkOut,
    region: regionCode,
    occupancy: params.occupancies ? serialiseOccupancy(params.occupancies) : undefined,
    flightOrigin: params.flightOrigin,
    preview: params.preview,
  }

  const isCruise = detectOfferTypeFromId(offerIds[0]) === 'cruise'
  const isExperience = detectOfferTypeFromId(offerIds[0]) === 'experience'

  if (isCruise || isExperience) {
    const offers = await Promise.all(offerIds.map(async offerId => {
      return getOfferById(offerId, { region: regionCode, currentCurrency: params.currentCurrency, isCruise, isExperience, channelMarkup }) })) as Array<App.CruiseOffer>
    return offers
  }

  const offerIdsByChunks = split(offerIds, 25)
  const offers = (await Promise.all(offerIdsByChunks.map(async(offerIdsChunk: Array<string>) => {
    const queryStrings = qs.stringify({
      ...baseQuery,
      offerIds: offerIdsChunk.join(','),
    })

    return request.get<App.ApiResponse<Array<PublicOfferV2.Offer>>>(`${uri}?${queryStrings}`, params.isSpoofed ? { credentials: 'include' } : {}).then(
      (response) => response.result.map(serverOffer => {
        try {
          if (serverOffer.type === OFFER_TYPE_BED_BANK) {
            return bedBankOfferMap(serverOffer)
          } else if (serverOffer.type === OFFER_TYPE_TOUR_V2) {
            return tourV2OfferSummaryMap(serverOffer)
          } else if (serverOffer.type === 'bundle_and_save') {
            return bundleOfferMap(serverOffer, regionCode, channelMarkup)
          } else if (serverOffer.type === 'tour') {
            return tourOfferMap(serverOffer)
          } else if (serverOffer.type === 'rental') {
            return villaOfferMap(serverOffer, regionCode, channelMarkup)
          } else {
            return hotelOfferMap(serverOffer, regionCode, channelMarkup)
          }
        } catch {
          return undefined
        }
      }))
  }))).flat()

  return nonNullable(offers)
}

interface GetOfferListParams {
  region: string;
  filters?: App.OfferListFilters;
  canViewLuxPlusBenefits: boolean;
  isLuxPlusEnabled: boolean;
  clientCheckAvailability?: boolean;
  evVersion?: 'current' | 'next';
  isSpoofed?: boolean;
  accessToken?: string;
  isPaidSession?: boolean;
  memberId?: string;
  domainUserId?: string;
  channelMarkup?: App.ChannelMarkup
}

interface StreamOfferListPayload {
  region: string;
  publicRates?: boolean;
  filters?: App.OfferListFilters;
  canViewLuxPlusBenefits: boolean;
  isLuxPlusEnabled: boolean;
  clientCheckAvailability?: boolean;
  evVersion?: 'current' | 'next';
  isPaidSession?: boolean;
  /** This is used for lere personalization */
  memberId?: string;
  /** This is used for lere personalization */
  domainUserId?: string;
  /** This is used for search streaming */
  searchId?: string;
  /** Number of offers to return from the initial request */
  offset?: number;
  /** This is used for lere personalization */
  personalise?: boolean;
  include?: Array<string>;
}

const supportedToursRequestTypes: Array<App.OfferListOfferType> = [
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_TOUR,
]

const supportedHotelRequestTypes: Array<App.OfferListOfferType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_VILLA,
]

/**
 * Determines whether we can use a new endpoint in search for getting an offer list
 * for hotels. This is a faster end point and should be used where possible.
 * @param params The filter list params to determine if we can use it
 * @returns Whether or not we can use the end point
 */
function isHotelSpecificRequest(params: GetOfferListParams) {
  return !!(params.filters?.offerTypes?.every(type => supportedHotelRequestTypes.includes(type))) &&
    params.filters.sortBy !== 'vertical-hotel' &&
    params.filters.sortBy !== 'carousel-hotel' &&
    params.filters.sortBy !== 'vertical-ultralux' &&
    params.filters.sortBy !== 'home' &&
    !params.filters.useUnifiedSearchEndpoint
}

function isTourSearchSpecificRequest(params: GetOfferListParams) {
  const filters = params.filters ?? {}

  return !!(filters.offerTypes?.every(type => supportedToursRequestTypes.includes(type))) && !filters.holidayTypes?.includes('Cruises') && params.filters?.sortBy !== 'vertical-ultralux' && !params.filters?.useUnifiedSearchEndpoint
}

function isUnifiedSpecificRequest(params: GetOfferListParams) {
  // only unified end point accepts multiple place ids at the moment
  return params.filters &&
    ((params.filters.placeIds?.length ?? 0) > 0 ||
      params.filters.sortBy === 'home' ||
      params.filters.sortBy === 'vertical-tour' ||
      params.filters.sortBy === 'vertical-hotel' ||
      params.filters.sortBy === 'carousel-hotel' ||
      params.filters.sortBy === 'vertical-ultralux' ||
      params.filters.sortBy === 'subregion-nsw' ||
      params.filters.sortBy === 'subregion-vic' ||
      params.filters.sortBy === 'vertical-cruise' ||
      params.filters.useUnifiedSearchEndpoint
    )
}

export interface OfferFilterResult {
  filters?: App.OfferListAvailableFilters;
  filterOrder?: App.OfferListFilterOrders;
}

export interface OfferListResult extends OfferFilterResult {
  searchId?: string;
  offerIds: Array<string>;
  metaData?: Array<App.OfferListMetaData>;
  tourMetadata?: Array<App.TourListMetadata>;
  offerCount?: number;
  searchVertical?: SEARCH_VERTICALS;
}

type UnifiedSearchResponse = paths['/api/search/unified/v1/list']['get']['responses']['200']['content']['application/json']

function getOfferTypesFromFilters(filters: App.OfferListFilters): Array<App.OfferListOfferType | App.ExperienceProductType> {
  if (!filters.offerTypes) return []
  return filters.offerTypes
    .map((offerType) => {
      // svc-search's offer type for experiences is the ExperienceProductType of CP,
      // that's why we need this mapping
      if (offerType === OFFER_TYPE_EXPERIENCE) {
        return config.UNIFIED_SEARCH_EXPERIENCES_TYPES?.length ? config.UNIFIED_SEARCH_EXPERIENCES_TYPES : []
      }
      return offerType
    })
    .flat()
}

export function getOfferList(params: GetOfferListParams): Promise<OfferListResult> {
  const { filters = {}, region, canViewLuxPlusBenefits, isLuxPlusEnabled, isPaidSession, isSpoofed, accessToken, channelMarkup } = params

  let searchType: string | undefined = undefined
  let placeId: string | undefined = undefined
  if (filters.destinationId) {
    searchType = 'destination'
    placeId = filters.destinationId
  }
  if (filters.landmarkId) {
    searchType = 'landmark'
    placeId = filters.landmarkId
  }

  const scheduleVisibilities = filters.scheduleVisibilities ? [...filters.scheduleVisibilities] : []

  if (!scheduleVisibilities.includes('listVisible')) {
    scheduleVisibilities.push('listVisible')
  }

  if (isLuxPlusEnabled) {
    scheduleVisibilities.push('luxPlusEarlyAccessVisible')
  }

  if (canViewLuxPlusBenefits) {
    scheduleVisibilities.push('luxPlusExclusiveVisible')
  }

  const types = getOfferTypesFromFilters(filters)

  const commonParams = {
    offerType: types.join(',') || undefined,
    checkIn: filters.checkIn,
    checkOut: filters.checkOut,
    flexibleMonths: filters.flexibleMonths || undefined,
    flexibleNights: filters.flexibleNights || undefined,
    campaigns: filters.campaigns?.join(',') || undefined,
    holidayTypes: reverseUpdateToBrandSpecificFilters(filters.holidayTypes)?.join(',') || undefined,
    holidayTypesScoped: filters.holidayTypesScoped?.join(',') || undefined,
    propertyTypes: filters.propertyTypes?.join(',') || undefined,
    locations: filters.locations?.join(',') || undefined,
    searchType,
    // Param is called `placeIds` but we only pass one ID, hence the conflicting pluralisation.
    // This is because we previously supported searching for multiple destinations.
    placeIds: placeId,
    distanceEq: filters.distanceEq,
    distanceGt: filters.distanceGt,
    sortBy: filters.sortBy,
    strategyApplied: filters.strategyApplied,
    region,
    occupancy: filters.rooms ? serialiseOccupancy(filters.rooms) : undefined,
    propertyId: filters.propertyId,
    searchNearby: filters.searchNearby,
    hasPromotions: filters.hasPromotions,
    bounds: filters.bounds,
    amenities: filters.amenities?.join(',') || undefined,
    limit: filters.limit || undefined,
    propertySubCategories: filters.propertySubCategories?.join(',') || undefined,
    starRatingGte: filters.starRatingGte,
    customerRatingGte: filters.customerRatingGte,
    inclusions: filters.inclusions?.join(',') || undefined,
    includeSpecificDatesResults: filters.includeSpecificDatesResults,
    priceLte: filters.priceLte,
    priceGte: filters.priceGte,
    productTypes: filters.productTypes,
    bedroomsGte: filters.bedroomsGte && filters.bedroomsGte > 0 ? filters.bedroomsGte : undefined,
    bedroomsEq: filters.bedroomsEq && filters.bedroomsEq > 0 ? filters.bedroomsEq : undefined,
    bedsGte: filters.bedsGte && filters.bedsGte > 0 ? filters.bedsGte : undefined,
    bathroomsGte: filters.bathroomsGte && filters.bathroomsGte > 0 ? filters.bathroomsGte : undefined,
    scheduleVisibilities: scheduleVisibilities?.join(',') || undefined,
    luxPlusExclusive: filters.luxPlusFeatures && filters.luxPlusFeatures.length > 0 ? filters.luxPlusFeatures?.join(',') : undefined,
    includeAllBedbankWithSales: filters.includeAllBedbankWithSales || undefined,
    isLuxPlusMember: canViewLuxPlusBenefits || undefined,
    agentHubExclusiveOffersOnly: filters.agentHubExclusiveOffersOnly || undefined,
    agentHubExclusive: filters.agentHubFeatures && filters.agentHubFeatures.length > 0 ? filters.agentHubFeatures?.join(',') : undefined,
  }

  if (isHotelSpecificRequest(params)) {
    // enforce the following filters for all hotel searches for LE Business Traveller.
    let businessTravellerParams = {}
    if (config.businessTraveller.currentAccountMode === 'business') {
      businessTravellerParams = {
        starRatingGte: 3,
      }
    }

    const finalParams = {
      ...commonParams,
      ...businessTravellerParams,
      placeIds: commonParams.placeIds ?? ANYWHERE_PLACE_ID,
      occupancy: (commonParams.occupancy && commonParams.occupancy.length > 0) ? commonParams.occupancy : serialiseOccupancy([{ adults: 1 }]),
      ...(filters.segments?.length && { segments: filters.segments.join(',') }),
      ...(params.clientCheckAvailability && { clientCheckAvailability: true }),
      ...(params.evVersion && { evVersion: params.evVersion }),
      paidSession: isPaidSession,
      useSvcAccommodation: params.filters?.useSvcAccommodation,
    }
    const queryString = qs.stringify(finalParams)

    const headerOptions = isSpoofed ? authOptions(accessToken) : {}

    return request.get<HotelSearchResultResponse>(`/api/search/hotel/v1/list?${queryString}`, headerOptions).then((response): OfferListResult => {
      if (response.total === 0) {
        logNewRelic('DEBUG ZSR', finalParams)
      }
      return {
        searchId: response.searchId,
        offerIds: response.result.map(res => res.id),
        metaData: response.result.map((item, index) => mapSearchResultToOfferListMetaData(item, index, channelMarkup)),
        filters: {
          amenities: response.filters.amenities,
          bedrooms: response.filters.bedrooms,
          campaigns: response.filters.campaigns,
          customerRatings: response.filters.customerRatings,
          holidayTypes: response.filters.holidayTypes,
          inclusions: response.filters.inclusions,
          propertyTypes: response.filters.propertyTypes,
          offerTypes: response.filters.type,
          luxPlusFeatures: response.filters.luxPlusExclusive ?? {},
          agentHubFeatures: response.filters.agentHubExclusive ?? {},
          total: response.total,
        },
        filterOrder: response.filterOrder,
        offerCount: response.total,
        searchVertical: SEARCH_VERTICALS.HOTELS,
      }
    })
  } else if (params.filters?.offerTypes?.includes('cruise') && !isUnifiedSpecificRequest(params)) {
    return getCruiseSearchList(params.filters, region).then(result => {
      return {
        offerIds: result,
        searchVertical: SEARCH_VERTICALS.CRUISES,
      }
    })
  } else if (isTourSearchSpecificRequest(params)) {
    return getTourSearchListByFacet({ ...params.filters, isLuxPlusMember: canViewLuxPlusBenefits }, region).then(response => {
      return {
        offerIds: response.result.map(result => {
          if (typeof result === 'string') {
            return result
          }
          return result.tourId
        }),
        tourMetadata: response.result.map(result => {
          if (typeof result === 'string') {
            return {
              tourId: result,
              tourOptionIds: [],
            }
          }
          return ({
            tourId: result.tourId,
            tourOptionIds: result.tourOptionIds,
          })
        }),
        searchVertical: SEARCH_VERTICALS.TOURS,
      }
    })
  } else {
    const queryString = qs.stringify({
      region,
      placeIds: filters.placeIds?.join(',') ?? commonParams.placeIds ?? ANYWHERE_PLACE_ID,
      limit: filters.limit || undefined,
      offerTypes: commonParams.offerType,
      sortBy: commonParams.sortBy,
      holidayTypes: commonParams.holidayTypes,
      holidayTypesScoped: commonParams.holidayTypesScoped,
      locations: commonParams.locations,
      campaigns: commonParams.campaigns,
      paidSession: isPaidSession,
      isLuxPlusMember: canViewLuxPlusBenefits || undefined,
      earlyAccessOffersOnly: filters.luxPlusFeatures?.includes('Early access') ? true : undefined,
      agentHubExclusiveOffersOnly: commonParams.agentHubExclusiveOffersOnly || undefined,
    })

    return request.get<UnifiedSearchResponse>(`/api/search/unified/v1/list?${queryString}`).then((response): OfferListResult => {
      return {
        offerIds: response.results.map(offer => offer.offerType == 'cruise' ? `cruise:${offer.bk}` : offer.bk),
        filters: {
          campaigns: transformFilterObject(response.filters.campaigns),
          holidayTypes: transformFilterObject(response.filters.holidayTypes),
          bedrooms: {},
          propertyTypes: {},
          amenities: {},
          offerTypes: {},
          luxPlusFeatures: {},
          agentHubFeatures: {},
          total: response.total,
        },
      }
    })
  }
}

export function getTraderInformation(id: string, region: string) {
  const uri = templates.offer.publicOfferTraderInformation.expand({ propertyId: id, region })

  return request.get<App.ApiResponse<PublicOfferV2.TraderInformation | null>>(uri)
    .then(async data => {
      if (data.result) {
        return traderInformationMap(data.result)
      }
      return null
    })
}

interface FlightPriceParams {
  region: string;
  flightOrigin: string;
  flightDestination: string;
  currency: string;
  duration: number;
  travelToDate?: string;
  forceBundleId?: string;
}

interface FlightPriceResult {
  journey: {
    cost: number;
    fees: number;
    price_breakdown?: unknown;
    outbound_route: any;
    returning_route: any;
  };
}

export function getOfferFlightPrice(params: FlightPriceParams) {
  const query = qs.stringify({
    start_date: moment().add(2, 'day').format(ISO_DATE_FORMAT),
    end_date: params.travelToDate ? params.travelToDate : moment().add(1, 'year').format(ISO_DATE_FORMAT),
    number_of_nights: params.duration,
    number_of_adults: 2,
    origin: params.flightOrigin,
    destination: params.flightDestination,
    currency: params.currency,
    region: params.region,
    force_bundle_id: params.forceBundleId,
  })

  return request.get<App.ApiResponse<FlightPriceResult>>(`/api/flights/single-cheapest-search?${query}`).then(response => {
    return response.result.journey.cost
  })
}

export function streamScrollRequest({ searchId, limit }: ScrollRequest) {
  const searchSocket = getSearchSocket()
  searchSocket.emit('search:scroll', { searchId, limit })
}

export function streamOfferListScroll(searchId: string, limit: number) {
  return new Promise<void>(resolve => {
    const searchSocket = getSearchSocket()

    streamScrollRequest({
      searchId,
      limit,
    })

    let count = 0

    const handleOffers = (response: StreamingHotelSearchOffersResponse) => {
      if (response.searchId !== searchId) return

      count += response.result.length

      if (count >= limit) {
        searchSocket.off('search:response:end', handleEnd)
        searchSocket.off('search:response:offers', handleOffers)
        resolve()
      }
    }

    const handleEnd = (response: { searchId: string}) => {
      if (response.searchId !== searchId) return

      searchSocket.off('search:response:end', handleEnd)
      searchSocket.off('search:response:offers', handleOffers)
      resolve()
    }

    searchSocket.on('search:response:end', handleEnd)
    searchSocket.on('search:response:offers', handleOffers)
  })
}

export function streamRequest(params: StreamOfferListPayload): void {
  const {
    filters = {},
    region,
    canViewLuxPlusBenefits,
    isLuxPlusEnabled,
    isPaidSession,
    memberId,
    domainUserId,
    searchId,
    offset,
    personalise,
    include,
    publicRates,
  } = params

  let searchType: string | undefined = undefined
  let placeId: string | undefined = undefined
  if (filters.destinationId) {
    searchType = 'destination'
    placeId = filters.destinationId
  }
  if (filters.landmarkId) {
    searchType = 'landmark'
    placeId = filters.landmarkId
  }
  if (filters.bounds) {
    searchType = 'map_area'
  }

  const scheduleVisibilities = filters.scheduleVisibilities ? [...filters.scheduleVisibilities] : []

  if (!scheduleVisibilities.includes('listVisible')) {
    scheduleVisibilities.push('listVisible')
  }

  if (isLuxPlusEnabled) {
    scheduleVisibilities.push('luxPlusEarlyAccessVisible')
  }

  if (canViewLuxPlusBenefits) {
    scheduleVisibilities.push('luxPlusExclusiveVisible')
  }

  const types = filters.offerTypes ?? []

  const commonParams = {
    offerType: types.join(',') || undefined,
    checkIn: filters.checkIn,
    checkOut: filters.checkOut,
    flexibleMonths: filters.flexibleMonths || undefined,
    flexibleNights: filters.flexibleNights || undefined,
    campaigns: filters.campaigns?.join(',') || undefined,
    holidayTypes: reverseUpdateToBrandSpecificFilters(filters.holidayTypes)?.join(',') || undefined,
    holidayTypesScoped: filters.holidayTypesScoped?.join(',') || undefined,
    propertyTypes: filters.propertyTypes?.join(',') || undefined,
    locations: filters.locations?.join(',') || undefined,
    searchType,
    // Param is called `placeIds` but we only pass one ID, hence the conflicting pluralisation.
    // This is because we previously supported searching for multiple destinations.
    placeIds: placeId,
    distanceEq: filters.distanceEq,
    distanceGt: filters.distanceGt,
    sortBy: filters.sortBy,
    strategyApplied: filters.strategyApplied,
    region,
    occupancy: filters.rooms ? serialiseOccupancy(filters.rooms) : undefined,
    propertyId: filters.propertyId,
    searchNearby: filters.searchNearby,
    hasPromotions: filters.hasPromotions,
    bounds: filters.bounds,
    amenities: filters.amenities?.join(',') || undefined,
    limit: filters.limit || undefined,
    propertySubCategories: filters.propertySubCategories?.join(',') || undefined,
    starRatingGte: filters.starRatingGte,
    customerRatingGte: filters.customerRatingGte,
    inclusions: filters.inclusions?.join(',') || undefined,
    includeSpecificDatesResults: filters.includeSpecificDatesResults,
    priceLte: filters.priceLte,
    priceGte: filters.priceGte,
    productTypes: filters.productTypes,
    bedroomsGte: filters.bedroomsGte && filters.bedroomsGte > 0 ? filters.bedroomsGte : undefined,
    bedroomsEq: filters.bedroomsEq && filters.bedroomsEq > 0 ? filters.bedroomsEq : undefined,
    bedsGte: filters.bedsGte && filters.bedsGte > 0 ? filters.bedsGte : undefined,
    bathroomsGte: filters.bathroomsGte && filters.bathroomsGte > 0 ? filters.bathroomsGte : undefined,
    scheduleVisibilities: scheduleVisibilities?.join(',') || undefined,
    luxPlusExclusive: filters.luxPlusFeatures && filters.luxPlusFeatures.length > 0 ? filters.luxPlusFeatures?.join(',') : undefined,
    agentHubExclusive: filters.agentHubFeatures && filters.agentHubFeatures.length > 0 ? filters.agentHubFeatures?.join(',') : undefined,
    includeAllBedbankWithSales: filters.includeAllBedbankWithSales || undefined,
    isLuxPlusMember: canViewLuxPlusBenefits || undefined,
    leUserId: memberId,
    domainUserId,
    personalise,
    include,
    publicRates,
    useSvcAccommodation: filters.useSvcAccommodation,
    rollout: filters.rollout,
  }

  let businessTravellerParams = {}
  if (config.businessTraveller.currentAccountMode === 'business') {
    businessTravellerParams = {
      starRatingGte: 3,
    }
  }

  if (isHotelSpecificRequest(params)) {
    const searchRequest = {
      ...commonParams,
      ...businessTravellerParams,
      placeIds: commonParams.placeIds ?? ANYWHERE_PLACE_ID,
      occupancy: commonParams.occupancy ? commonParams.occupancy : serialiseOccupancy([{ adults: 1 }]),
      ...(filters.segments?.length && { segments: filters.segments.join(',') }),
      ...(params.clientCheckAvailability && { clientCheckAvailability: true }),
      ...(params.evVersion && { evVersion: params.evVersion }),
      paidSession: isPaidSession,
      brand: config.BRAND as StreamingHotelSearchResultRequest['brand'],
      minOffersRequired: offset,
      searchId,
    } as unknown as StreamingHotelSearchResultRequest

    globalSearchSocket?.emit('search', searchRequest)
  }
}

interface PropertyOferMappingResult {
  id: string;
  name: string;
  reservationId: string;
  bedbankIds: Array<string>;
  rooms: Record<string, {
    id: string;
    name: string;
    bedbankIds: Array<string>;
    reservationId: string;
    supplierIds: Array<string>;
  }>
}

export function getPropertyOfferMapping(propertyId: string): Promise<App.PropertyOfferMapping> {
  const query = qs.stringify({
    reservationPropertyId: propertyId,
  })

  return request.get<App.ApiResponse<PropertyOferMappingResult>>(
    `/api/accommodation/properties/mappings?${query}`,
    { excludeBrand: true },
  )
    .then(response => {
      const serverMapping = response.result
      const allRoomMaps = Object.values(serverMapping.rooms)
      return {
        propertyId,
        bedbankOfferId: serverMapping.bedbankIds[0],
        rooms: arrayToObject(
          allRoomMaps,
          room => room.reservationId,
          room => ({
            roomId: room.reservationId,
            bedbankRoomIds: room.bedbankIds,
          }),
        ),
      }
    })
}

export function getOfferIdBySlug(
  slug: string,
  type: 'offer',
): Promise<string | null> {
  if (detectOfferTypeFromId(slug)) {
    return Promise.resolve(null)
  }

  const uri = templates.offer.publicOfferRetrieveId.expand({ slug, type })

  return request.get<App.ApiResponse<PublicOfferV2.RetrieveIdResponseBody['result']>>(uri)
    .then(async response => response.result.offerId)
}
