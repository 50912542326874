import { sortBy } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getTourV2ExperienceItems } from './toursv2'
import { CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE } from 'constants/checkout'

const getTourV2ExperienceItemsViews = createSelector(
  (state: App.State) => getTourV2ExperienceItems(state),
  (tourExperienceItems): App.WithDataStatus<Array<App.Checkout.TourV2ExperienceItemView>> => {
    if (tourExperienceItems.length === 0) { return { hasRequiredData: true, data: [] } }
    const tourExperienceItemView = sortBy(
      tourExperienceItems.map((item): App.Checkout.TourV2ExperienceItemView => ({
        kind: CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
        packageId: item.purchasableOption.fkExperienceId!,
        uniqueKey: item.purchasableOption.fkExperienceId!,
        item,
        luxLoyaltyProductType: 'tour_optional_extra',
        primaryTitle: item.purchasableOption.name ?? '',
        removeLabel: 'Remove',
        totals: {
          price: item.total ?? 0,
          memberPrice: 0,
          value: 0,
          surcharge: 0,
          taxesAndFees: 0,
          memberValue: 0,
          extraGuestSurcharge: 0,
        },
      })),
      item => item.item.purchasableOption.day,
      'asc',
    )
    return {
      hasRequiredData: true,
      data: tourExperienceItemView,
    }
  },
)

export default getTourV2ExperienceItemsViews
