import { getTourV2IdForCheckoutItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { addTotals } from 'checkout/lib/utils/payments/totals'
import getTourV2ExperienceItemsViews from 'checkout/selectors/view/getTourV2ExperienceItemsViews'
import { isSalesforceTourV2 } from 'lib/offer/offerUtils'

export const cartIncludesTourV2 = (state: App.State) => state.checkout.cart.items.some(isTourV2Item)

export const isCartItemLeTour = (state: App.State) => {
  const cartItems = state.checkout.cart.items
  const tourV2Offers = state.offer.tourV2Offers
  const item = cartItems.find(isTourV2Item)
  if (!item) {
    return false
  }
  const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
  return isSalesforceTourV2(offer)
}

export const getTourExperienceTotals = createSelector(
  (state: App.State) => getTourV2ExperienceItemsViews(state),
  (offerViewsWithStatus): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const tourExperienceTotalView = offerViewsWithStatus.data.map<App.WithDataStatus<App.Checkout.ItemViewTotals>>(item =>
      ({ hasRequiredData: offerViewsWithStatus.hasRequiredData, data: item.totals }),
    )
    return {
      hasRequiredData: offerViewsWithStatus.hasRequiredData,
      data: addTotals(tourExperienceTotalView).data,
    }
  },
)
